"use client";

import React, {
  useEffect,
  Fragment,
  useState,
  useMemo,
  useCallback,
} from "react";
import CartManager from "./component/CartManager";

import moment from "moment";
import Footer from "./component/Footer";
import ColorPicker from "./component/ColocPicker";
import { Dialog, Transition } from "@headlessui/react";
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { axios, productStats } from "./Network";
import toast, { Toaster } from "react-hot-toast";

export default function ProductDetail() {
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(false);
  let [isOpen, setIsOpen] = useState(true);
  let [selectedColor, setColor] = useState({ name: "", hex: "" });
  let [currentView, setCurrentView] = useState(1);
  const [userDate, setDate] = useState(null);
  const [currentProduct, setCurrentProduct] = useState({
    title: "",
    subtitle: "",
    imgUrl: "",
    status: false,
  });
  const [currentProductV2, setCurrentProductV2] = useState({
    title: "",
    subtitle: "",
    imgUrl: "",
    status: false,
  });
  const [currentImage, setCurrentImage] = useState("");
  const [currentPath, setCurrentPath] = useState(undefined);
  const queryParams = new URLSearchParams(location);
  const [cart, setCartItems] = useState([]);
  const [storeDetails, setStoreInfo] = useState(null);
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    getProduct();
    let existingCart = localStorage.getItem("UserCart");
    let store = localStorage.getItem("store_details");

    setStoreInfo(JSON.parse(store));
    var cart = JSON.parse(existingCart);
    if (cart != null) {
      setCartItems(cart);
    }
  }, [currentPath]);

  async function getProduct() {
    try {
      var productTitle = decodeURI(window.location.pathname.substring(1));
      const extractedValue = productTitle.split("/").pop();
      if (productTitle != "") {
        var parameter = {
          isAPI: true,
          queryData: { _id: decodeURI(extractedValue) },
          queryType: "custom",
        };
        axios
          .post("/store/Product", parameter)
          .then((response) => {
            if (response.data.count != 0) {
              setCurrentProduct(response.data.results[0]);

              setCurrentProductV2(response.data.results[0]);
              setCurrentImage(response.data.results[0].imgUrl);
              setShow(true);
            } else {
              toast.error("Please try other product.");
            }
          })
          .catch(function (error) {
            setShow(false);
            console.log("fetching product", error);
          });
      }
    } catch (error) {
      return null;
    }
  }
  const validateItem = (e) => {
    try {
      let existingCart = localStorage.getItem("UserCart");
      var cart = JSON.parse(existingCart);
      const found = cart.find((obj) => {
        return obj._id === e._id;
      });

      let status = found === undefined ? true : false;
      return status;
    } catch (error) {
      return true;
    }
  };

  async function recordProductStats(e, type) {
    try {
      const data = {
        storeOwner: "TheFlowerLuxeCebu",
        cType: "cartBehavior",
        cName: "website",
        data: e,
        date: new Date(),
        action: type,
      };
      const response = await productStats.put("", data);
      return response;
    } catch (error) {}
  }
  const handleAddToCart = () => {
    const copyProduct = JSON.parse(JSON.stringify(currentProduct));

    if (selectedColor.hex !== "black") {
      copyProduct.title = `${currentProduct.title}-(${selectedColor.name})`;
    } else {
      // || selectedColor.hex !== "black"
      copyProduct.title = `${currentProduct.title}`;
    }
    CartManager.addItem(copyProduct);
    recordProductStats(copyProduct, "addToCart");
    toast.success(`${currentProduct.title} added`);
  };

  const handleBuyNow = () => {
    handleAddToCart();
    setTimeout(() => {
      window.location.href = "/cart";
    }, 1000);
  };

  const toggleWishlist = () => {
    // setIsWishlisted(!true);
    // toast.success(true ? "Removed from wishlist" : "Added to wishlist");
  };

  const loaderUI = () => {
    return (
      <>
        <div className="max-w-4xl mx-auto p-4 animate-pulse">
          {/* Product Header */}
          <div className="mb-2 h-4 w-32 bg-gray-200 rounded"></div>
          <div className="mb-6 h-8 w-64 bg-gray-200 rounded"></div>

          {/* Product Grid */}
          <div className="grid md:grid-cols-2 gap-8">
            {/* Left - Image Gallery */}
            <div className="space-y-4">
              <div className="aspect-square bg-gray-200 rounded-lg"></div>
              <div className="grid grid-cols-2 gap-4">
                <div className="aspect-square bg-gray-200 rounded-lg"></div>
                <div className="aspect-square bg-gray-200 rounded-lg"></div>
              </div>
            </div>

            {/* Right - Product Details */}
            <div className="space-y-4">
              <div className="flex items-center gap-2 mb-4">
                <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
                <div className="h-4 w-40 bg-gray-200 rounded"></div>
              </div>

              {/* Description */}
              <div className="space-y-2">
                <div className="h-4 w-full bg-gray-200 rounded"></div>
                <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
                <div className="h-4 w-5/6 bg-gray-200 rounded"></div>
              </div>

              {/* Price */}
              <div className="flex justify-between items-center py-4">
                <div className="h-6 w-24 bg-gray-200 rounded"></div>
                <div className="h-6 w-24 bg-gray-200 rounded"></div>
              </div>

              {/* Color Options */}
              <div className="py-4">
                <div className="h-5 w-32 bg-gray-200 rounded mb-3"></div>
                <div className="flex gap-2">
                  {[1, 2, 3, 4, 5, 6].map((i) => (
                    <div
                      key={i}
                      className="h-12 w-12 bg-gray-200 rounded-lg"
                    ></div>
                  ))}
                </div>
              </div>

              {/* Buttons */}
              <div className="space-y-3 pt-4">
                <div className="h-12 w-full bg-gray-200 rounded-full"></div>
                <div className="h-12 w-full bg-gray-200 rounded-full"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const mainContent = () => {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 flex flex-col md:flex-row gap-8">
        {/* Left side - Images */}
        <div className="md:w-2/3 space-y-4">
          <div className="aspect-square w-full bg-[#f5f5f5] rounded-lg overflow-hidden">
            <img
              src={currentProduct.imgUrl}
              alt="Theodore Armchair"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="grid grid-cols-2 gap-4 hidden">
            <div className="aspect-square bg-[#f5f5f5] rounded-lg overflow-hidden">
              <img
                src={currentProduct.imgUrl}
                alt="Chair Detail"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="aspect-square bg-[#f5f5f5] rounded-lg overflow-hidden">
              <img
                src={currentProduct.imgUrl}
                alt="Chair Detail"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>

        {/* Right side - Product Info */}
        <div className="md:w-1/3">
          <div className="sticky top-8">
            <p className="text-gray-500 mb-2 text-sm ">Flc Collection</p>
            <h1 className="text-3xl font-semibold mb-4">
              {currentProduct.title}
            </h1>
            <h2 className="flex items-center gap-2   mb-8">
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Delivery from 24 hours
            </h2>

            <button className="w-full text-left flex items-center justify-between py-4 border-t">
              <h2 className="">Description</h2>
            </button>
            <div className="py-4">
              <p className="text-gray-600 text-xs">{currentProduct.subtitle}</p>
            </div>
            <div className="flex justify-between items-center py-4 border-t">
              <div>
                <h2 className="text-sm">Regular price</h2>
                <p className="text-2xl font-semibold ">
                  {new Intl.NumberFormat("en-PH", {
                    style: "currency",
                    currency: "PHP",
                  }).format(currentProduct.price)}
                </p>
              </div>
              {/* <div>
                <h2 className="text-sm">
                  Member (Save £209 for £100 per year)
                </h2>
                <p className="text-2xl font-semibold">
                  {new Intl.NumberFormat("en-PH", {
                    style: "currency",
                    currency: "PHP",
                  }).format(currentProduct.price)}
                </p>
              </div> */}
            </div>
            {/* <ColorPicker
              options={currentProduct.options}
              selectedColor={(e) => setColor(e)}
              disabled={(e) => toast.error("Not available")}
            /> */}
            <div className="space-y-4 sticky bottom-4 right-0">
              <button
                onClick={() => handleAddToCart()}
                className="w-full flex   align-middle items-center justify-center py-3 border rounded-full hover:bg-gray-50 bg-white text-xs"
              >
                <img
                  src={"/addToCart.png"}
                  className=""
                  style={{
                    height: 16,
                    width: 24,
                    marginRight: 10,
                  }}
                />
                Add to cart
              </button>
              <button
                style={{ backgroundColor: "#e29797" }}
                onClick={() => handleBuyNow()}
                className="w-full py-3 flex   align-middle items-center justify-center text-white rounded-full hover:bg-gray-900 text-xs"
              >
                <img
                  src={"/cart@3x.png"}
                  className=""
                  style={{
                    height: 16,
                    width: 15,
                    marginRight: 10,
                  }}
                />
                Buy Now
              </button>
            </div>
            <div className="mt-8 space-y-4 hidden">
              <button className="w-full text-left flex items-center justify-between py-4 border-t">
                <span className="font-medium">DIMENSIONS</span>
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <button className="w-full text-left flex items-center justify-between py-4 border-t">
                <span className="font-medium">FABRIC DETAILS</span>
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <button className="w-full text-left flex items-center justify-between py-4 border-t">
                <span className="font-medium">
                  LARGE ITEM DELIVERY & RETURNS
                </span>
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    );
  };
  return (
    <>
      <header className="sticky bg-white top-0 z-50 w-full bg-white/80 backdrop-blur-md hover:shadow-lg">
        <div className="flex items-center justify-between px-4 py-4">
          <button
            onClick={() => window.history.back()}
            className="p-2 rounded-full hover:bg-gray-100 transition-colors"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-gray-700"
            >
              <path d="M19 12H5M12 19l-7-7 7-7" />
            </svg>
          </button>

          <h1 className="text-xl font-semibold text-gray-900">
            {currentProduct.title}
          </h1>

          <button
            className="p-2 rounded-full hover:bg-gray-100 transition-colors"
            aria-label="More options"
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-gray-700"
            >
              <circle cx="12" cy="12" r="1" />
              <circle cx="19" cy="12" r="1" />
              <circle cx="5" cy="12" r="1" />
            </svg> */}
          </button>
        </div>
      </header>
      {show === false ? loaderUI() : mainContent()}
    </>
  );
}


import { useState, useEffect, useMemo } from 'react'

import { useHistory, Switch } from "react-router-dom";
import '.././App.css';
import { Tab } from '@headlessui/react'
import { PaymentOption } from '../component/PaymentOption'

import AddressPicker from '../component/AddressPicker'
import StoreLocation from '../component/location'
import OTPContent from '../component/OTPInput'

import { TimeValidator } from '../Utils/ClosingTimeValidator'
import CartDetails from './Cart'
import { axios, productStats, axiosOTP } from '../Network'

import moment from 'moment-timezone'
import toast, { Toaster } from 'react-hot-toast';
import ContentLoader from "react-content-loader"
import { nextWednesday } from 'date-fns';
const notify = () => toast('Here is your toast.');
const storeID = process.env.REACT_APP_STORE_ID
// const flux = '60b1c9a9a001ef1e463d52c2' //'Flux-Bouquet'
// const nidz = '5ff00ddaeb2f5d0940dfa186' // Dried
let peakTime = [
  { time: "February 12 2023", opening: "09:00:00", closing: "12:00:00" },
  { time: "February 13 2023", opening: "13:00:00", closing: "18:00:00" },
  { time: "February 14 2023", opening: "09:00:00", closing: "12:30:00" }
]

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={340}
    height={84}
    viewBox="0 0 340 84"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
    <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
    <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
    <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
    <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
  </ContentLoader>
)
var id = process.env.REACT_APP_STORE_ID
// https://heroicons.com/
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function MyModal() {

  let [categories] = useState({
    Delivery: [
      {
        id: 1,
        title: 'Does drinking coffee make you smarter?',
        date: '5h ago',
        name: 'CustomersName',
        isRequired: true,
        title: "Customer's Name",
        placeHolder: 'Juan Dela Cruz',
        commentCount: 5,
        shareCount: 2,
        state: (e) => {
          setCustomerName(e)
        },
        value: () => {
          return customerName
        },
      },
      {
        id: 2,
        date: '2h ago',
        name: 'MobileNumber',
        isRequired: true,
        title: "Customer's Mobile Number",
        placeHolder: "09** **** ***",
        commentCount: 3,
        shareCount: 2,
        state: (e) => {
          setCustomerMobile(handleMobileNumberChange(e))

        }, value: () => {
          return customerMobile
        }
      }, {
        id: 3,
        name: 'MobileNumber',
        isRequired: true,
        title: "Receiver's Name",
        placeHolder: 'e.g. Angelica Dela Cruz',
        date: 'Mar 19',
        commentCount: 24,
        shareCount: 12,
        state: (e) => {
          setReceiverName(e)
        }, value: () => {
          return receiverName
        }
      }, {
        id: 4,
        name: 'MobileNumber',
        isRequired: true,
        title: "Receiver's Mobile Number",
        placeHolder: "09** **** ***",
        date: 'Mar 19',
        commentCount: 24,
        shareCount: 12,
        state: (e) => {
          setReceiverMobile(handleMobileNumberChange(e))
        }, value: () => {
          return receiverMobile
        }
      }, {
        id: 4,
        name: 'CustomerEmail',
        isRequired: false,
        title: "Email",
        placeHolder: "Juan Dela Cruz",
        commentCount: 24,
        shareCount: 12,
        state: (e) => {
          setCustomerEmail(e)
        }, value: () => {
          return customerEmail
        }
      }

    ],
    Pickup: [
      {
        id: 1,
        title: 'Does drinking coffee make you smarter?',
        date: '5h ago',
        name: 'CustomersName',
        isRequired: true,
        title: "Customer's Name",
        placeHolder: 'Juan Dela Cruz',
        commentCount: 5,
        shareCount: 2,
        state: (e) => {
          setCustomerName(e)
        },
        value: () => {
          return customerName
        },
      },
      {
        id: 2,
        date: '2h ago',
        name: 'MobileNumber',
        isRequired: true,
        title: "Customer's Mobile Number",
        placeHolder: "09** **** ***",
        commentCount: 3,
        shareCount: 2,
        state: (e) => {
          setCustomerMobile(handleMobileNumberChange(e))

        }, value: () => {
          return customerMobile
        }
      }, {
        id: 4,
        name: 'CustomerEmail',
        isRequired: false,
        title: "Email",
        placeHolder: "Juan Dela Cruz",
        commentCount: 24,
        shareCount: 12,
        state: (e) => {
          setCustomerEmail(e)
        }, value: () => {
          return customerEmail
        }
      }
    ]
  }
  )//DummyJS)
  let [isOpen, setIsOpen] = useState(true)
  const history = useHistory();  // do this inside the component
  const [enabled, setEnabled] = useState(false)
  const [store, setStoreDetails] = useState(null)
  const [paymentsList, setPaymentOptions] = useState(null)
  const [payment, setSelectePayment] = useState({
    itemName: "GCash",
    key: "xx2",
    itemDetails: "0917 843 4776",
    status: true,
    icon_url: "https://getcash.ph/wp-content/uploads/2021/01/Gcash-logo-Transparent-500x500-1.png",
    service_fee: {
      amount: 0
    }


  })
  const [orderType, setOrderType] = useState(0) // 1- Delivery - 0 Pickup
  const [customerName, setCustomerName] = useState(null)
  const [customerMobile, setCustomerMobile] = useState(null)
  const [receiverName, setReceiverName] = useState(null)
  const [receiverMobile, setReceiverMobile] = useState(null)
  const [customerEmail, setCustomerEmail] = useState(null)
  const [cart, setCartItems] = useState([])
  const [notes, setNotes] = useState(null)
  const [burialNote, burialMessage] = useState('')
  const [landmark, setLandMark] = useState(null)
  const [prefferedBranch, setbranch] = useState("at Base Line Residences")
  const [promocode, setPromoCode] = useState('')
  const [userDate, setDate] = useState(null)
  const [orderRef, setOrderReference] = useState(null)
  const [totalGrandPrice, setTotalGrandPrice] = useState(0)
  const [isSuccess, setSuccess] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [storeReady, didLoadStore] = useState(false)
  const [isValidatePromo, setValidatePromo] = useState(false);
  const [isAgree, setDidAgree] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("02:00 PM");
  const [discountedPromo, setDiscountedPromo] = useState(0);
  const [isPriceBelow, setPriceBelow] = useState(false);
  const [deliveryRate, setDeliveryRate] = useState({
    price: 350,
    title: "cbx-70",
    displayName: "Cebu City",
  })
  const [discountedDetails, setDiscountedDetails] = useState({
    "requiredAmount": 0,
    "discountedPrice": 0,
    "requiredCategory": []
  });
  const [isCustomerHide, setHideCustoemrDetails] = useState(false)
  const [isDateTimeHide, setDateTimeHide] = useState(false)
  const goBack = () => history.goBack()

  const handleMobileNumberChange = (mobileInput) => {
    const sanitizedValue = mobileInput.replace(/[^0-9]/g, '');
    return sanitizedValue
  };

  function loadStorePaymentOptions() {
    let parameter = {
      id: "6526a9bd12f32bf4b0f37a1b",
      equalTo: true, storeOwner: "6526a9bd12f32bf4b0f37a1b", cType: "Product", cName: "Category"
    }
    axios
      .post("/details/StoreSettings", parameter)
      .then((response) => {

        setPaymentOptions(response.data.results[0].paymentOptions)
        // didLoadStore(true)
        // document.title = "Cart - " + response.data.results[0].storeName
      })
      .catch(function(error) {
        toast('Something went wrong, please try again')
      });
  }
  const validate16HoursOrder = ()=>{
    const formattedDateTime = moment(generateFormmatedDate(), 'MMMM Do YYYY [at] hh:mm A');
    const hoursDifference = moment().diff(formattedDateTime, 'hours');
    const isValidOrder =  hoursDifference > 0  || moment(userDate).isSameOrBefore(moment(), 'day')
    return isValidOrder
  }
  function loadProductCategory() {
    let parameter = {
      id: id,
      equalTo: true, storeOwner: id, cType: "Product", cName: "Category"
    }
    axios
      .post("/details/Store", parameter)
      .then((response) => {
        setStoreDetails(response.data.results[0])
        didLoadStore(true)
        document.title = "Cart - " + response.data.results[0].storeName
      })
      .catch(function(error) {
        toast('Something went wrong, please try again')
      });
  }

  useEffect(() => {
    let existingCart = localStorage.getItem('UserCart')
    if (existingCart !== null) {
      let jsonCart = JSON.parse(existingCart)
      setCartItems(jsonCart)
    }
    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)

    if (date != null) {
      setStartDate(moment(date.date).tz("Asia/Taipei").toDate())
      setDate(moment(date.date).tz("Asia/Taipei").toDate())
    } else {
      setDate(moment(newDate()).tz("Asia/Taipei").toDate())
    }
    loadProductCategory()
    loadStorePaymentOptions()

  }, [])


  useEffect(() => {
    if (peakTime.some(item => item.time === moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY"))) {
      setSelectedTime('12:00 AM')
    }
  }, [startDate])


  async function recordTimeBehavior(e) {
    try {
      const data = { storeOwner: "TheFlowerLuxeCebu", cType: "deliveryTimeBehavior", cName: "website", "userPreferredTime": e, "date": new Date(), totalCart: getGrandTotalPrice(deliveryRate.price, true) }
      const response = await productStats.put('', data)
      return response
    } catch (error) {
    }
  }
  useEffect(() => {

    if (startDate === null) {
      return
    }

    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)

    if (date != null) {

      setDate(moment(date.date).tz("Asia/Taipei").toDate())
    } else {

      setDate(moment(newDate()).tz("Asia/Taipei").toDate())
    }
  }, [startDate])
  const newDate = () => {
    var new_date = moment(new Date()).tz("Asia/Taipei");
    return new_date.add(1, 'days');
  }
  const validateDetails = () => {
    alert('s')

  }

  function closeModal() {
    setIsOpen(false)
  }

  async function promoCodeService(e) {
    try {
      const data = { code: e, storeOwner: id, deliveryDate: startDate }
      const response = await axios.post('/validate/PromoCode', data)
      return response.data
    } catch (error) {
      console.log(error)
      alert('something went wrong')
    }
  }

  function openModal() {
    setIsOpen(true)
  }

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  function successContent(e) {
    let path = `/`;
    setOrderReference(e)
    setSuccess(true)
    localStorage.removeItem('UserCart')
    localStorage.removeItem('userQ')
  }
  const generateFormmatedDate = () => {
    return `${moment(startDate).tz("Asia/Taipei").format("MMMM Do YYYY")} at ${selectedTime}`
  }
  async function placeOrderState() {
    setLoading(true)
    try {
      setLoading(true)
      let id = makeid(10)
      let branch = ` My preferred branch: ${prefferedBranch}`
      let additionalMessage = `${orderType === 1 ? branch : ''}`
      let payload = {
        storeOwner: 'storeID',
        cType: "Order",
        cName: "Items",
        cart: cart,
        reference: id,
        resellersID: 'null',
        details: {
          customerName: customerName,
          contact: 'Email',
          mobile: customerMobile,
          Receiver: receiverName,
          ReceiverContactNumber: receiverMobile,
          StreetAddress: `${landmark}, ${deliveryRate.displayName}`,
          orderType: orderType === 1 ? 'Store-Pickup' : 'Delivery',
          selectedDate: userDate,
          formattedDate: generateFormmatedDate(),//moment(userDate).tz("Asia/Taipei").format('MMMM Do YYYY, h:mm:ss a'),
          deliveryNotes: notes + `${orderType === 1 ? additionalMessage : ''}`,
          burialMessage: burialNote,
          deliveryRateDetails: deliveryRate,
          email: customerEmail
        },
        storeDetails: store.storeContact,
        storeWebsite: store.Website,
        tracker: store.storeOptions.tracker,
        storeCodeName: store.codeName,
        payment: payment,
        total: getGrandTotalPriceV2(deliveryRate.price, true),
        code: promocode === '' ? "NONE" : promocode
      }
      storePayload(payload)

      let response = await axios
        .put('/Order', payload)

      let emailData = response.data.results
      emailData.subject = `Your FLC Order#:${emailData.orderReference} (ON HOLD)`
      emailData.headLine = "FLC Order Online"

      await axiosOTP
        .post('/notifier', emailData)

      return response
    } catch (error) {
      return null
    }
  }
  const storePayload = (payloads) => {
    try {
      localStorage.setItem('userCache', JSON.stringify(payloads))
    } catch (error) {
      toast.error('Please try again later.')
    }
  }
  const validateAddons = useMemo(() => {
    var required = 1
    let allowed = cart.filter((item: { type: string; }) => item.type != "Food" && item.type != "Tart-Addons" && item.type != "Gifts")
    return allowed.length >= required ? true : false
  }, [cart])

  function validateOrderedDate() {

    try {

      let userOrderedDate = startDate //generateFormmatedDate()
      var dateToday = new Date()
      return moment(userOrderedDate).isAfter(dateToday);

    } catch (error) {
      alert('errorr', error)
      return false
    }


  }
  function validateBurial() {
    var isFound = cart.filter(item => item.type === "Funerals")?.length

    return isFound ? <>
      <label className=" block  ml-3 tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Funeral Message
      </label>
      <textarea onChange={(e) => burialMessage(e.nativeEvent.target.value)} className=" w-80 px-3 py-2  ml-3 text-sm   bg-gray-100  text-gray-700 border rounded-lg focus:outline-none" placeholder="E.g. In loving memory..., Forever in our thoughts..." rows="4" />

    </> : null
  }
  function processOrder(e) {


    if (orderType === 0 && (receiverName === null || receiverMobile === null || customerMobile === null || customerName === null || landmark === null)) {

      toast('Please fill the required delivery details')
      return
    } else if (orderType === 1 && (customerMobile === null || customerName === null)) {
      toast('Please fill the required pickup details')
      return
    } else if (userDate === null) {
      toast(`Please add ${orderType === 0 ? 'delivery ' : 'pickup '}date & time`)
      return
    }

    try {

      if (validateOrderedDate()) {
        placeOrderState().then((res) => {
          if (res != null) {
            successContent(res.data)
          } else {
            toast.error('Please try again later.')

          }
        })
      } else {
        toast.error('Please pick a future date.')

      }

    } catch (error) {
      alert('error')

    }

  }

  //API Before submit
  const getOperationFee = () => {
    try {
      let initialPrice: number = 0

      let total = cart.reduce((sum, current) => sum + Number(current.price), 0);
      let serviceFeeAndCart = (total * payment.service_fee.amount) / 100
      return serviceFeeAndCart

    } catch (error) {
      return 1000
    }
  }
  function getGrandTotalPrice(df, isGrandTotal, fee) {
    let initialPrice = 0
    try {
      var getTotalflatRate = orderType === 1 ? 0 : df
      cart.map(data => { initialPrice += data.price * 1 })
      let service = getTotalflatRate + fee
      return isGrandTotal ? new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(initialPrice + Number(service) - getFinalPromo()) : new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(initialPrice + Number(service))

    } catch (error) {
      // alert('error')

      toast('Something went wrong, please try again')
      // setTotalGrandPrice(0)
      return 0
    }
  }

  //API SUBMIT SERVICE
  function getGrandTotalPriceV2(df, isGrandTotal) {
    let initialPrice = 0
    try {
      var getTotalflatRate = orderType === 1 ? 0 : df
      cart.map(data => { initialPrice += data.price * 1 })
      let service = getTotalflatRate + getOperationFee()
      return initialPrice + Number(service) - getFinalPromo()

    } catch (error) {
      // alert('error')

      toast('Something went wrong, please try again')
      // setTotalGrandPrice(0)
      return 0
    }
  }
  function getGrandTotalPriceInt(df) {
    let initialPrice = 0
    try {
      var getTotalflatRate = orderType === 1 ? 0 : df
      cart.map(data => { initialPrice += data.price * 1 })
      let service = getTotalflatRate
      return initialPrice + Number(service)

    } catch (error) {
      // alert('error')
      toast('Something went wrong, please try again')
      // setTotalGrandPrice(0)
      return 0
    }
  }
  function paymentToggle(e) {
    setSelectePayment(e)
  }
  function renderMobileAppContent() {
    try {
      return (
        <div
          class=" inset-0 flex flex-col items-start justify-end ml-4  "
        >

          <div className='ml-4 ' style={{ marginTop: 40 }}>
            <p className='text-xs text-gray-700 font-light'>For credit/debit card users, download FlowerLuxe Cebu app.</p>
            < a href={store != null ? store.storeOptions.appLinks.apple : ''}>
              <span
                class="mt-1.5 inline-block  rounded-lg   font-medium uppercase tracking-wide lg:mt-8 "
              >
                <img src="https://p7.hiclipart.com/preview/833/232/867/app-store-google-play-button-download-now-button.jpg" className='hover:shadow-lg border-black ' width={120} heigth={20} />
              </span>
            </a>
            < a href={store.storeOptions != null ? store.storeOptions.appLinks.android : ''}>

              <span
                class="mt-1.5 inline-block    rounded-lg font-medium uppercase tracking-wide "
              >
                <img src="https://www.citypng.com/public/uploads/small/11639742284pbvh9xefaiftrwoqxd6jgyhjwjie4vmuaet3dc7h63j9mmcr6jbxmvkefmlslxwws7iybxn0z1q4u8arplb2g4bpg9tsbuez1xlg.png" className='hover:shadow-lg' width={120} heigth={23} />
              </span>
            </a>
          </div>
        </div>
      )
    } catch (error) {
      return ''
    }

  }
  const toggleView = () => {
    return (
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11`}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`${enabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </Switch>
    )
  }

  function displayDF() {
    if (orderType === 0) {
      return (
        <h3 className="text-xs    ml-6 text-left   font-xs  leading-5">
          <span className="text-gray-900 font-bold ">   Delivery Fee: </span>             {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(deliveryRate.price)}
        </h3>
      )
    }

  }
  const displayColorBG = () => {
    try {
      return store.storeOptions.colorTheme
    } catch {

      return 'white'
    }
  }
  const updateOrderType = (e) => {
    setOrderType(e)

    setCustomerName(null)
    setCustomerMobile(null)
    setReceiverName(null)
    setReceiverMobile(null)
  }
  const successOrder = () => {
    var dateToday = new Date()
    return (
      <>
        <div className="min-h-screen px-4 text-center">
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block w-full max-w-md p-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl  overflow-auto" style={{ height: 'auto', overflow: 'auto' }}>
            {/* <h2 className="text-xl    p-6 font-medium">
          Order has been placed.

         </h2> */}
            <h2 className="text-xl  text-black  sm:text-center  lg:text-center sticky margin: 20rem my-10  p-2 font-medium">
              Your order has been placed!
            </h2>

            {/* <label className=" block ml-6 tracking-wide text-gray-700 text-xs font-light mb-2" for="grid-first-name">
       Your order has been placed!
       </label>  */}
            <div />




            <div className=" w-full md:w-full px-3 mb-5  md:mb-0  mt-5 ">
              <img src="https://gatesbbq.com/wp-content/uploads/2017/04/checkmarksuccess.gif" className="w-auto h-auto" />


              <div className=" sm:text-center  lg:text-center sticky margin: 20rem my-10 text-4xl tracking-tight content-center   text-gray-900 sm:text-2xl md:text-6xl mb-90 font-bold">

                {orderRef.results.orderReference}
                <label className=" block tracking-wide text-gray-700 text-xs font-light mb-2" for="grid-first-name">
                  Reference Code
                </label>

              </div>
              <div class="bg-white  text-center py-4 lg:px-4" style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <div class="p-2 bg-red-100 items-center text-indigo-100 leading-none rounded-full  lg:rounded-full flex lg:inline-flex" role="alert">
                  <span class="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs  text-white font-bold mr-3">Status</span>
                  <span class="font-semibold mr-2 text-left text-yellow-900 flex-auto">Payment Required</span>
                  <svg class="fill-current opacity-75 h-4 w-4 text-yellow-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"> <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" /></svg>
                </div>
                <div class="p-2 font-semibold ">Amount due : {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(orderRef.results.totalPrice)}</div>

              </div>
              <h3 className="   text-gray-700 text-xs font-xs font-light  mb-2  leading-5">
                Selected Payment Method: <span className='font-bold'>{payment.itemName} - {payment.itemDetails} </span><br />
                Account Holder Name: <span className='font-bold'>  Jeciel Ann Arda </span>
              </h3>



              <div className="w-full bg-black mt-10 mb-5" style={{ height: 0.5, opacity: 0.2 }} />
              <label className=" block  justify-center  mt-5 tracking-wide  text-gray-700 text-sm font-light mb-2" for="grid-first-name">
                Your booking will be put on hold until <span class="font-semibold mr-2 text-left text-blue-900 flex-auto">{getProperTyime()}.</span>Please make payment to avoid your booking being automatically  <span class="font-bold mr-2 text-left text-red-500 flex-auto uppercase">cancelled</span>.
              </label>

              {/* <div class="bg-white  text-center py-4 lg:px-4" style={{alignContent:'center',justifyContent:'center',alignItems:'center'}}>
  <div class="p-2 bg-red-100 items-center text-indigo-100 leading-none rounded-full  lg:rounded-full flex lg:inline-flex" role="alert">
    <span class="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs  text-white font-bold mr-3">Status</span>
    <span class="font-semibold mr-2 text-left text-yellow-900 flex-auto">Payment Required</span>
    <svg class="fill-current opacity-75 h-4 w-4 text-yellow-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"> <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" /></svg>
  </div>
  <div class="p-2 font-semibold ">Amount due : ₱200.00</div>

</div> */}
              <div>
                <div className="mt-5  text-gray-700 ">What to do next?
                  <ul class="list-none md:list-disc font-light text-xs ml-5  text-gray-700 ">
                    <li>Complete your transaction with your chosen  <span class="font-semibold mr-2 text-left text-blue-900 flex-auto">Payment Method.</span></li>
                    <li>Then upload screenshot using the link below</li>

                  </ul>
                </div>
                <button
                  disabled={cart.length !== 0 ? false : true}
                  type="button"
                  className={"inline-flex  w-full justify-center mt-5 px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"}
                  onClick={() => window.open(`${store.storeOptions.tracker}/${orderRef.results.orderReference}`, "_blank")}
                >
                  Upload your proof of payment here
                  <a href="/Cart" >
                  </a>
                </button>

              </div>


              {/* <button
             disabled={cart.length  !== 0 ? false : true}
               type="button"
               className= {"inline-flex  justify-center mt-20 px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"   }
               onClick={()=>history.push('/')}
             >
               Back to Store
             <a href="/Cart" ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
               </svg></a>
             </button> */}



              {/* <button

        type="button"
        className="inline-flex ml-4 w-100 justify-center px-4 py-2 text-sm font-medium   text-black bg-gray-100 border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        onClick={goBack}
        >

        Save Order
        <a href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className=" h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
        </svg></a>
        </button> */}
              {/*
             <label className=" block   mt-2 tracking-wide text-gray-700 text-xs font-light mb-2" for="grid-first-name">
             Client has an option to book their own delivery service. However, for any damages or losses, FLC will not responsible *

             </label> */}

            </div>
            {/* <div class="px-6 pt-4 pb-2">
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#facebook</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#ins</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
  </div> */}
          </div>

        </div>

      </>
    )
  }
  function getTotalItemPerCategory(requiredCategories) {
    var initialPrice = 0
    if (requiredCategories === undefined) {
      return 0
    }
    cart.map(data => {
      if (requiredCategories.includes(data.subCategory) ||
        requiredCategories.includes(data.type)) {
        initialPrice += data.price * 1
      }
    })

    return initialPrice
  }

  // function renderTime(){
  const selectedTimeServiceLogger = (time) => {
    recordTimeBehavior(time)
    setSelectedTime(time)
  }
  const renderTime = (item) => {
    return (
      <button
        onClick={() => selectedTimeServiceLogger(item)}

        type="button"
        className={selectedTime === item ? "bg-black  inline-flex hover:scroll-auto  hover:shadow-lg border-black justify-center px-4 py-2 text-xs font-medium ml-4 text-white border border-transparent rounded-full ease-in-out duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" : " focus:ring-indigo-500 inline-flex mb-2 hover:bg-black ease-in-out duration-300   justify-center px-4 py-2 text-xs font-medium ml-4 text-gray-600 hover:text-white bg-gray-100 border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"}
      >
        {true ? <> <div className=""><span className="font-xs " >{item}</span></div>
          {'category' !== 'item.title' ? <div className=""><span className="font-xs ml" ></span></div> : <div className="">
            <svg version="1.1" id="loader-1" className="animate-spin h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" space="preserve">
              <path opacity="0.2" fill="#FFFF" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
       s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
       c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
              <path fill="#FFFF" className="border-white border-2" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
       C22.32,8.481,24.301,9.057,26.013,10.047z">
              </path>
            </svg>
          </div>}
        </> : <><div className=""><span className="font-xs " >{'item.displayName'} +</span></div>
          <a href="javascript:void(0);" >
          </a> </>}

      </button>
    )
  }
  function generateListOfTime(opening, closing) {
    let list = []
    let afternoonShift = "12:30:00"
    let morningShift = "13:00:00"


    if (moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY") === peakTime[0].time) {

      let currentTime = moment(peakTime[0].opening, 'hh:mm A')
      try {
        for (let i = 0; currentTime.isBefore(moment(peakTime[0].closing, 'hh:mm A')); i++) {
          let convertedTime = currentTime.add(30, 'minutes').toDate()
          let timeString = moment(currentTime).format('hh:mm A')
          let datePickerobject = {
            time: convertedTime,
            isSpecialHours: true,
            displayTime: timeString
          }
          list.push(datePickerobject)

        }
        return list
      } catch (error) {
        console.log('error Time covertter', error)
      }
    } else if (moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY") === peakTime[1].time) {
      let currentTime = moment(peakTime[1].opening, 'hh:mm A')
      try {
        for (let i = 0; currentTime.isBefore(moment(peakTime[1].closing, 'hh:mm A')); i++) {
          let convertedTime = currentTime.add(30, 'minutes').toDate()
          let timeString = moment(currentTime).format('hh:mm A')
          let datePickerobject = {
            time: convertedTime,
            isSpecialHours: true,
            displayTime: timeString
          }
          list.push(datePickerobject)

        }
        return list
      } catch (error) {
        console.log('error Time covertter', error)
      }
    } else if (moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY") === peakTime[2].time) {
      let currentTime = moment(peakTime[2].opening, 'hh:mm A')
      try {
        for (let i = 0; currentTime.isBefore(moment(peakTime[2].closing, 'hh:mm A')); i++) {
          let convertedTime = currentTime.add(30, 'minutes').toDate()
          let timeString = moment(currentTime).format('hh:mm A')
          let datePickerobject = {
            time: convertedTime,
            isSpecialHours: true,
            displayTime: timeString
          }
          list.push(datePickerobject)

        }
        return list
      } catch (error) {
        console.log('error Time covertter', error)
      }
    } else {
      //REGULAR DAYS
      try {
        let currentTime = moment(opening, 'hh:mm A')





        let userSeletedDate = localStorage.getItem('userQ')
        var date = JSON.parse(userSeletedDate)
        let startDate = ''
        startDate = moment(date.date).tz("Asia/Taipei")



        if (TimeValidator(startDate).status) {
          currentTime = moment("13:30:00", 'hh:mm A')
        }
        for (let i = 0; currentTime.isBefore(moment(closing, 'hh:mm A')); i++) {
          let convertedTime = currentTime.add(30, 'minutes').toDate()
          let timeString = moment(currentTime).format('hh:mm A')
          let datePickerobject = {
            time: convertedTime,
            isSpecialHours: true,
            displayTime: timeString
          }
          list.push(datePickerobject)

        }
        return list



      } catch (error) {

        console.log('error Time covertter', error)
      }
    }
  }

  function renderListTime() {

    try {

      if (store === null) {
        return <MyLoader />
      }

      let timeZ = store.storeOptions.businessHours
      let openingTime = timeZ.opening
      let closeTime = timeZ.closing
      console.log('moment(userDate', moment(userDate).tz("Asia/Taipei").format("YYYY-MM-DD").toString())
      if (timeZ.openingTime.affectedDate.includes(moment(userDate).tz("Asia/Taipei").format("YYYY-MM-DD").toString())) {
        openingTime = timeZ.openingTime.opening
      }



      let convertedTime = moment(openingTime, 'hh:mm A').add(130, 'minutes').toDate()


      let listOfConverterTime = generateListOfTime(moment(openingTime, 'hh:mm A').toDate(), moment(closeTime, 'hh:mm A').toDate())



      let list = []
      let time = []
      list = listOfConverterTime
      list.map(item => {

        time.push(renderTime(item.displayTime))
      })


      if (peakTime.some(item => item.time === moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY"))) {
        return null
      } else {
        return time
      }

    } catch (error) {
      console.log('error generating time', error)
      return <MyLoader />
    }
  }
  function getFinalPromo() {
    var total = 0
    if (discountedDetails.discountedPrice === 0) {
      return 0
    } else {
      try {
        let taggedPromo = discountedDetails.requiredCategory

        if (discountedDetails.isPercentage) {


          var totalOrder = getTotalItemPerCategory(taggedPromo)

          total = totalOrder >= discountedDetails.requiredAmount ? (discountedDetails.discountedPrice / 100) * totalOrder : 0
          return total
        } else {
          var totalOrder = getTotalItemPerCategory(taggedPromo)

          total = totalOrder >= discountedDetails.requiredAmount ? discountedDetails.discountedPrice : 0
          return total
          // return totalOrder

        }
      } catch (error) {
        return 0
      }
    }

  }
  function renderOrderMessage() {
    if (store === null) {
      return <MyLoader />
    }


    var messages = store.storeOptions.delivertInfo //["Other areas not on the list will be charged accordingly depending on the distance *","Client has an option to book their own delivery service. However, for any damages or losses, FLC will not be responsible. * "]
    var contents = []
    messages.map(item => {
      contents.push(
        <label className=" block   mt-2 tracking-wide text-gray-700 text-xs font-extralight mb-2" for="grid-first-name">
          {item}
        </label>
      )
    })
    return contents
  }
  async function promocodeServiceAsync(promocodeReference) {
    try {

      let item = await promoCodeService(promocode)
      try {
        if (item.result.isValid) {
          var promoCodeType = item.result.promoDetails
          let taggedPromo = item.result.promoDetails.requiredCategory  //Pass the list of categories


          var total = getGrandTotalPriceInt(0)
          var totalOrder = getTotalItemPerCategory(taggedPromo)
          total = totalOrder >= promoCodeType.requiredAmount ? (promoCodeType.discountedPrice / 100) * totalOrder : 0
          if (total == 0) {
            setPromoCode('')
            // discountedDetails.discountedPrice = 0
            // toast(`[${promocodeReference}] ${item.result.promoDetails.message}`)
            // toast(`[${promocodeReference}] ${item.result.promoDetails.message}`)
            setDiscountedDetails(item.result.promoDetails)
            return `[${promocodeReference}] ${item.result.promoDetails.message}`
          } else {
            setDiscountedDetails(item.result.promoDetails)
            // toast(`${promocodeReference} has been applied `)
            return `${promocodeReference} has been applied `
          }

        } else {
          setPromoCode('')
          // toast(`[${promocodeReference}] Sorry, your code is invalid.`)
          return `[${promocodeReference}] Sorry, your code is invalid.`
        }
      } catch (error) {

        setPromoCode('')
        // toast(`[${promocodeReference}] Sorry, your code is invalid.`)
        return `[${promocodeReference}] Sorry, your code is invalid.`
      }

    } catch (error) {
      // return 'ERRPRR'
    }
  }
  function submitPromoCode() {

    var promocodeReference = promocode
    if (promocodeReference === '' || promocodeReference === null) {
      return
    }

    //PARK:
    // setValidatePromo(true)
    // toast.promise(promocodeServiceAsync(promocodeReference), {
    //   loading: 'Validating.. ...',
    //   success:  (data) => {
    //     console.log(data);
    //     if (data.status === 500) throw new Error('server error');
    //     return data;
    //   },
    // error: 'Uh oh, there was an error!',
    // });


    setValidatePromo(true)
    promoCodeService(promocode).then(item => {
      setValidatePromo(false)
      try {
        if (item.result.isValid) {
          var promoCodeType = item.result.promoDetails
          let taggedPromo = item.result.promoDetails.requiredCategory  //Pass the list of categories
          setDiscountedDetails(item.result.promoDetails)
          var total = getGrandTotalPriceInt(0)
          var totalOrder = getTotalItemPerCategory(taggedPromo)
          total = totalOrder >= promoCodeType.requiredAmount ? (promoCodeType.discountedPrice / 100) * totalOrder : 0
          if (total == 0) {
            setPromoCode('')
            toast(`[${promocodeReference}] ${item.result.promoDetails.message}`)
          } else {
            toast(`${promocodeReference} has been applied `)
          }
        } else {
          setPromoCode('')
          toast(`[${promocodeReference}] Sorry, your code is invalid.`)
        }
      } catch (error) {

        setPromoCode('')
        toast(`[${promocodeReference}] Sorry, your code is invalid.`)
      }
    })
  }
  const displayPaymentOptionFee = () => {
    if (payment.service_fee.amount != 0) {
      return (
        <h3 className="text-xs  mb-5  ml-6 text-left   font-xs  leading-5">
          <span className="text-gray-900 text-xs font-bold "> Paypal Service Fee : </span>  {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(getOperationFee())}
          <p class="text-xs text-gray-400">A service fee of {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(getOperationFee())} (5%) will be added to your subtotal.</p>
        </h3>
      )
    }
  }
  const displayBottomView = () => {
    return <>
      <div class="" style={{ width: 'auto', height: 1, backgroundColor: 'gray', opacity: 0.5, marginTop: 10 }} />
      <div className="inline-block w-full  p-6 my-1 text-left align-middle transition-all transform " >
        <h3 className="ext-black text-xs font-bold    leading-5">
          Order Summary
        </h3>
        <div className={`mt-2 bar overflow-auto  ${payment.key === "xx3" ? '' : ''}`} style={{ height: 'auto', overflow: 'auto' }}>
          <CartDetails items={cart} removeItem={(e) => console.log(e)} showDelete={false} />
        </div>
      </div>
      <div className="inline-block w-full  p-6 my-1 text-left align-middle transition-all transform " >
        <h3 className="ext-black text-xs font-bold    leading-5">
          Apply Promotion
        </h3>
        <div className="mt-2 bar  overflow-auto" style={{ height: 'auto', overflow: 'auto' }}>
          <input disabled={getFinalPromo() != 0 ? true : false} value={promocode} onChange={(e) => setPromoCode(e.nativeEvent.target.value)} placeHolder="Enter code " className={"w-1/2  text-xs appearance-none blockbg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"} id="grid-first-name" type="text" />
          <button disabled={promocode === '' ? true : getFinalPromo() != 0 ? true : false} onClick={() => submitPromoCode()}
            class={isValidatePromo ? " font-md    justify-center font-medium   lg:ml-4 text-white bg-gray-700 border border-transparent  text-sm py-2  rounded inline-flex items-center" : getFinalPromo() != 0 ? "font-md    justify-center  font-medium ml-4 text-black bg-none border border-transparent  text-sm py-2 px-2 rounded-full inline-flex items-center" : " font-md    justify-center  font-medium ml-4 text-white bg-black border border-transparent  text-sm py-2 px-4 rounded-full inline-flex items-center"}
            style={{ backgroundColor: getFinalPromo() != 0 ? '#f5f6fa' : displayColorBG().buttonColor }}
          >
            <span style={{ opacity: isValidatePromo ? 0.2 : getFinalPromo() != 0 ? 1 : 1 }}>{getFinalPromo() != 0 ? 'Code Applied' : 'Apply Code '}</span>

            {getFinalPromo() != 0 ?
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-0" fill={'green'} viewBox="0 0 24 24" stroke='green'>
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ opacity: isValidatePromo ? 0.3 : 1 }}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
              </svg>}
          </button>
        </div>
      </div>
      <h3 className="text-xs  mb-5  ml-6 text-left   font-xs  leading-5">
        <span className="text-gray-900 text-xs font-bold ">  Subtotal : </span>  {getGrandTotalPrice(0, false, 0)}
      </h3>

      {displayPaymentOptionFee()}
      {displayDF()}
      {getFinalPromo() === 0 ? null : <h3 className="text-xs   mt-5 ml-6 text-left   font-bold  leading-5">
        <span className="text-gray-900 text-xs font-md ">  Promo  : </span> <span className="text-red-500 ">- {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(getFinalPromo())}</span>
      </h3>}

      <div className="inline-block w-full    p-6 text-left align-middle transition-all transform " >
        <h3 className="text-md font-bold leading-5">
          <span className="text-gray-900 font-bold ">  Grand Total: </span> <span className="text-green-600 font-md ">{getGrandTotalPrice((deliveryRate.price), true, getOperationFee())}</span>
        </h3>
      </div>
      <div className="bg-gray-500  w-full " style={{ height: 0.2 }} />
      <div class={isAgree ? "flex mt-6 border border-gray-200 rounded-lg bg-gray-100" : "flex mt-6 border border-red-500 rounded-lg bg-red-100"} onClick={() => setDidAgree(!isAgree)}>
        <label class="flex items-center m-4 " >
          <input type="checkbox"  id="agree" onChange={(e) => setDidAgree(!isAgree)} checked={isAgree} class="form-checkbox" />
            <span class="ml-2 text-xs">I agree of the <a class="underline text-xs" href="/TermsAndCondition">Terms & Condition</a></span>
            </label>
      </div>
    </>
  }
  const displayOrderMessageComponent = () => {
    return (<>

      <label className=" block  ml-3 tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Order Notes / Special Instruction (optional)
      </label>
      <textarea onChange={(e) => setNotes(e.nativeEvent.target.value)} className=" w-80 px-3 py-2  ml-3 text-sm   bg-gray-100  text-gray-700 border rounded-lg focus:outline-none" placeholder="Type it here" rows="4" />

      {store === null ? null : orderType === 0 ?

        <>
          {validateBurial()}
          <div className=" w-full md:w-full px-3 mb-5  md:mb-0  mt-5 ">

            <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
              Address
            </label>
            {storeReady ? <AddressPicker className="z-50 " selected={(e) => setDeliveryRate(e)} details={store} /> : <MyLoader />}


          </div>
          <div className=" w-full md:w-full px-3 mb-5  md:mb-0  mt-5 ">

            <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
              Location Landmark<span className="text-red-400 ml-1">*</span>
            </label>
            <input value={landmark} onChange={(e) => setLandMark(e.nativeEvent.target.value)} placeHolder="e.g. Bldg 2nd floor,Juana Street " className={"  text-xs appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"} id="grid-first-name" type="text" />
            <div className="w-full bg-black mt-10 mb-5" style={{ height: 0.5, opacity: 0.2 }} />
            {renderOrderMessage()}

          </div>
        </>
        :
        <StoreLocation branch={(branch) => setbranch(branch)} />}
    </>

    )
  }
  const inProgressOrder = () => {
    return (

      <>

        <div className="min-h-screen px-4 mb-20  text-center">

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Toaster />
          <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl  overflow-auto" style={{ height: 'auto', overflow: 'auto' }}>
          {disclosureAlert()}
            <h2 className="text-xl    p-6 font-medium">
              Order  {orderType === 0 ? "Delivery" : "Pick-up"}
            </h2>
            {/* <span className="ml-5 text-lg ">{moment(startDate).format("MMMM DD YYYY")}</span> */}
            <div className={`w-full max-w-md px-2 py-2 sm:px-0  ${isLoading ? 'opacity-10' : ''}`}>

              <Tab.Group
                onChange={(e) => updateOrderType(e)}>
                <Tab.List className="flex p-1  space-x-1 bg-white-900/20 rounded-full">
                  {Object.keys(categories).map((category) => (
                    <Tab
                      key={category}
                      className={({ selected }) =>
                        classNames(
                          'w-full py-2.5 text-sm leading-5  font-medium text-black  ring-black rounded-full',
                          'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-black  ring-white  text-black ring-opacity-60',
                          selected
                            ? 'bg-black shadow text-white'
                            : 'text-black hover:bg-black/[0.9] hover:text-gray-400'
                        )
                      }
                    > {category}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels className="mt-2 ">

                  {Object.values(categories).map((posts, idx) => (

                    <Tab.Panel
                      key={idx}
                      className={classNames(
                        'bg-white rounded-xl p-3',
                        'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-white-400 ring-white ring-opacity-60'
                      )}
                    >


                      {isCustomerHide ? null : posts.map((post) => (
                        <>
                          <div className=" w-full md:w-full px-3 mb-6 md:mb-0">
                            <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                              {post.title} <span className="text-red-400">*</span>
                            </label>
                            <input key={post.id} value={post.value()} onChange={(e) => post.state(e.nativeEvent.target.value)} placeHolder={post.placeHolder} className={"appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"} id="grid-first-name" type="text" />

                          </div> </>
                      ))}



                      <img
                        style={{ position: 'absolute', top: 30, right: 20 }}
                        class={false ? "" : "ml-60 lg:w-12 lg:h-12 hover:opacity-80  m-2 shadow-lg md:w-12 md:h-full rounded w-16 h-16 md:block  "} src={store === null ? 'https://localflowershop.sgp1.digitaloceanspaces.com/product/1645683427653-flc-2022.jpeg' : store.storeLogo} />

                      {isCustomerHide ? '' : displayOrderMessageComponent()}



                      <button onClick={() => setHideCustoemrDetails(!isCustomerHide)} class="text-xs ml-2 bg-gray-200 rounded-full mt-4 mb-4" >

                        <div className='m-2 mt-2 mb-2 flex  '>
                          <img src="https://cdn-icons-png.flaticon.com/512/2956/2956793.png" className="h-4 w-auto mr-2" />
                          {isCustomerHide ? 'Show more ' : 'Show less'}</div> </button>

                      <div class="" style={{ width: 'auto', height: 1, backgroundColor: 'gray', opacity: 0.5, marginTop: 10 }} />

                      {/* <div className=" w-full mt-2 p-6  text-left align-middle transition-all transform " >
                 <div className="flex-col ... ">
             <h3 className="text-sm   font-medium leading-5 ">
             Upload Video Message
         </h3>
         </div>
         <div className="w-full  py-2 sm:px-0">
<img src="https://cdn.dribbble.com/users/767646/screenshots/1940258/cam.gif" className="h-20 w-auto"/>
            </div>


            <span className="text  italic text-xs">Your video will attached to your flowers and scan by qrcode.  <br/></span>

            <button
            type='file'
             disabled={cart.length  !== 0 ? false : true}
               type="button"
               className= {cart.length  !== 0 ? "inline-flex  justify-center px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" :"inline-flex  mt-2 justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-gray-200 border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  }
               onClick={()=>console.log(null)}
             >
               Select Video
             <a href="/Cart" ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
               </svg></a>
               <input type='file' class="hidden" />
             </button>

             <div className="flex items-center justify-between w-full ">
             <div className="flex items-center">
               <div className="text-sm">

       </div>
       </div>
       </div>
           </div> */}

                      <div className=" w-full md:w-full px-3 mb-6 mt-10 md:mb-0">
                        <h3 className="   text-black text-xs font-bold  flex leading-5">
                          {orderType === 0 ? "Delivery" : "Pick-up"} Date and Time<img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 mt-1" /> <span className="text-red-400 ">*</span>
                        </h3>
                        <label className=" block tracking-wide text-blue-800 font-bold text-xs font-md mt-2 mb-0" for="grid-first-name">
                          {moment(startDate).tz("Asia/Taipei").format("MMMM Do YYYY")}  {peakTime.some(item => item.time === moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY")) ? '(Within the day)' : `at ${selectedTime}`}
                        </label>
                        <div className="mb-4    grid grid-cols-7  lg:grid-cols-3 md:grid-cols-5 gap-2 bg-red-500" />
                        {isDateTimeHide ? null : renderListTime()}
                      </div>




                      <button onClick={() => setDateTimeHide(!isDateTimeHide)} class="text-xs ml-2 bg-gray-200 rounded-full mt-4 mb-4" >

                        <div className='m-2 mt-2 mb-2 flex  '>
                          <img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-auto mr-2 " />
                          {isDateTimeHide ? 'Show more ' : 'Show less'}</div> </button>



                      {/* <button  onClick={()=>setDateTimeHide(!isDateTimeHide)} class="text-xs ml-8 bg-gray-200 rounded-full mt-4" ><div className='m-2 mt-2 mb-2'>{true === false?'Show more payments' : 'Show less'}</div> </button> */}

                      <div className="w-full bg-black mt-10 mb-5" style={{ height: 0.5, opacity: 0.2 }} />

                      <div className="inline-block w-full  p-6 my-8 text-left align-middle transition-all transform ">
                        {/* <div className="flex justify-between ">
              <h3 className="   text-black text-xs font-bold   leading-5"> Payment Method - <span class="text-blue-800">{payment.itemName}</span></h3>
               <svg class="svg-icon"  className="h-5 w-5"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 96C282.25 96 96 282.25 96 512s186.25 416 416 416 416-186.25 416-416S741.75 96 512 96z m0 544l-192-192h384l-192 192z"  /></svg>

          </div> */}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
               </svg> */} {/* <svg class="svg-icon"  className="h-5 w-5"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M295.2 421.4c-15 15-15 39.6 0 54.6l191.4 190.8c14.6 14.6 38.2 15 53.2 1.2l188.6-188c7.6-7.6 11.4-17.4 11.4-27.4 0-9.8-3.8-19.8-11.2-27.2-15-15-39.4-15.2-54.6 0l-162 159.6-162.2-163.8c-15-15-39.4-15-54.6 0.2z"  /><path d="M96 512c0 229.8 186.2 416 416 416s416-186.2 416-416S741.8 96 512 96 96 282.2 96 512z m664.8-248.8C827.4 329.6 864 418 864 512s-36.6 182.4-103.2 248.8C694.4 827.4 606 864 512 864s-182.4-36.6-248.8-103.2C196.6 694.4 160 606 160 512s36.6-182.4 103.2-248.8C329.6 196.6 418 160 512 160s182.4 36.6 248.8 103.2z"  /></svg> */}
                        <div className="w-full py-2 sm:px-0 ">
                          {paymentsList === null ? <MyLoader /> : <PaymentOption did={() => console.log('collapsed now')} isCollapsed={true} isItinerary={false} details={paymentsList} selectePayment={(e) => setSelectePayment(e)} />}
                          {payment.key === "xx3" ? renderMobileAppContent() : null}
                        </div>

                      </div>

                      {payment.key != "xx3" ? displayBottomView() : null}

                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>



            <div className={`mt-4 ${payment.key === "xx3" ? 'hidden' : 'visible'}`}>


              {validateAddons ? null : <div
                className="mb-4"
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <p class="text-red-500 text-xs font-bold ">  FLOWERS REQUIRED</p>
                <p category="c1" className="text-xs" style={{ marginTop: 10 }}>Sorry, you can't purchase without flowers.</p>
              </div>}

              {displatBottomButtons()}

            </div>
          </div>
        </div>
      </>
    )
  }

  var getProperTyime = () => {
    var dateToday = new Date().toString()
    var date = moment(dateToday).tz("Asia/Taipei")
      .add(120, 'minutes')
      .format('LLL [(Today)] ');
    return date
    // return  moment(dateToday).toDate()
  }

  const OTPContent2 = () => {
    return <OTPContent store={store} />//<>WELCOME</>
  }
  const contentTypeGenerator = () => {
    let type = "OTP" // IN-PROGRESS // OTP // SUBMITTED
    switch (type) {
      case 'SUBMITTED':
        return successOrder()
      case 'OTP':
        return OTPContent2()
      case 'IN-PROGRESS':
        return inProgressOrder()
      default:
        return inProgressOrder()
    }
  }
  const displatBottomButtons = ()=>{
    if (!validate16HoursOrder()) {
      return (<>



        {isLoading ? '' : <button
          disabled={isLoading}
          type="button"
          className="inline-flex  w-100 justify-center px-4 py-2 text-sm font-medium   text-black bg-gray-100 border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={goBack}
        >Cancel
          <a href="/" ><svg xmlns="http://www.w3.org/2000/svg" className=" h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg></a>
        </button>}





        {validateAddons ? isLoading ? <button type="button" class="bg-rose-600 ..." disabled>
          <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
          </svg>
          Processing...
        </button> :
          <button
            disabled={cart.length !== 0 ? false : true}
            type="button"
            className={cart.length !== 0 ? "inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white   border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" : "inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-gray-200 border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"}
            style={{
              opacity: isAgree ? 1 : 0.5,
              backgroundColor: displayColorBG().buttonColor
            }}
            onClick={() => processOrder(null)}
          >
            Place order
            <a href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg></a>
          </button> : null}
        </>)
    }return <button
      disabled={isLoading}
      type="button"
      className="inline-flex  w-100 justify-center px-4 py-2 text-sm font-medium   text-black bg-gray-100 border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      onClick={goBack}
    >Back to store
      <a href="/" ><svg xmlns="http://www.w3.org/2000/svg" className=" h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg></a>
    </button>
  }
  const disclosureAlert=()=>{
    try {
      if (validate16HoursOrder()) {
        return (
          <div className="fixed z-50" style={{position:'absolute' ,top:0,right:0}}>
               <div className="overflow-hidden leading-normal rounded-lg  " >
                 <p className="px-4 py-3 font-bold text-purple-100 bg-purple-800">Unable to complete your order request.</p>
                 <p className="px-4 py-3 text-purple-700 bg-purple-100">Unfortunately, your order is not feasible. We need a minimum of 23 hours prior to the delivery date.</p>
               </div>
             </div>


        //   <div class="overflow-hidden leading-normal rounded-lg position-absolute top-0 left-0 right-0 mx-auto w-full" role="danger">
        //   <p class="px-4 py-3 font-bold text-purple-100 bg-purple-800">You're being notified</p>
        //   <p class="px-4 py-3 text-purple-700 bg-purple-100 ">Unfortunately, your order is not feasible. We need a minimum of 16 hours prior to the delivery date</p>
        // </div>
        )
      }

    }catch(error){
      return null
    }
  }
  return (
    <>
      {/* {contentTypeGenerator()} */}

      {isSuccess ? successOrder() : inProgressOrder()}
    </>

  )
}

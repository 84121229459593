"use client";

import toast, { Toaster } from "react-hot-toast";

class CartManager {
  constructor() {
    if (CartManager.instance) {
      return CartManager.instance;
    }
    CartManager.instance = this;
    this.cart = this.loadCart();
  }

  loadCart() {
    try {
      const savedCart = localStorage.getItem("UserCart");
      return savedCart ? JSON.parse(savedCart) : [];
    } catch (error) {
      console.error("Error loading cart:", error);
      return [];
    }
  }

  saveCart() {
    try {
      localStorage.setItem("UserCart", JSON.stringify(this.cart));
      localStorage.setItem("UserCart_Cache", JSON.stringify(this.cart));
    } catch (error) {
      console.error("Error saving cart:", error);
      toast.error("Failed to save cart");
    }
  }

  getCart() {
    return this.cart;
  }

  addItem(item) {
    try {
      const existingItem = this.cart.find(
        (cartItem) => cartItem._id === item._id,
      );

      if (!existingItem) {
        const itemWithDate = {
          ...item,
          dateAdded: new Date(),
        };
        this.cart.push(itemWithDate);
        this.saveCart();
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error adding item:", error);
      toast.error("Failed to add item");
      return false;
    }
  }

  removeItem(itemId) {
    try {
      const initialLength = this.cart.length;
      this.cart = this.cart.filter((item) => item._id !== itemId);

      if (this.cart.length !== initialLength) {
        this.saveCart();
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error removing item:", error);
      toast.error("Failed to remove item");
      return false;
    }
  }

  clearCart() {
    this.cart = [];
    this.saveCart();
    toast.success("Cart cleared");
  }

  getItemCount() {
    return this.cart.length;
  }
}

export default new CartManager();
